import type { AppCamperSearchQuery } from '@yescapa-dev/ysc-api-js/modern'
import { LOCALE_CODES } from '@yescapa-dev/ysc-components/i18n'

export const getDefaultSearchGeoloc = (yscCode: string): Required<Pick<AppCamperSearchQuery, 'longitude' | 'latitude' | 'radius'>> => {
  switch (yscCode) {
    case LOCALE_CODES.CA:
      return {
        longitude: 1.564178,
        latitude: 41.520917,
        radius: 141575,
      }
      break
    case LOCALE_CODES.DE:
      return {
        longitude: 10.299683,
        latitude: 51.679368,
        radius: 457513,
      }
      break
    case LOCALE_CODES.DE_AT:
      return {
        longitude: 14.849396,
        latitude: 47.589494,
        radius: 127436,
      }
      break
    case LOCALE_CODES.DE_CH:
      return {
        longitude: 8.307037,
        latitude: 46.991494,
        radius: 128894,
      }
      break
    case LOCALE_CODES.EN_GB:
      return {
        longitude: -2.8509521484375,
        latitude: 54.348552775876065,
        radius: 494541,
      }
      break
    case LOCALE_CODES.EN_IE:
      return {
        longitude: -8.033752,
        latitude: 53.271783,
        radius: 224061,
      }
      break
    case LOCALE_CODES.ES:
      return {
        longitude: -3.630981,
        latitude: 39.749434,
        radius: 570438,
      }
      break
    case LOCALE_CODES.FR:
      return {
        longitude: 1.8292236328125002,
        latitude: 47.04766864046083,
        radius: 445695,
      }
      break
    case LOCALE_CODES.FR_BE:
      return {
        longitude: 4.936981,
        latitude: 50.715591,
        radius: 119597,
      }
      break
    case LOCALE_CODES.FR_CH:
      return {
        longitude: 7.002411,
        latitude: 46.985874,
        radius: 128908,
      }
      break
    case LOCALE_CODES.IT:
      return {
        longitude: 13.562622,
        latitude: 40.254377,
        radius: 566089,
      }
      break
    case LOCALE_CODES.IT_CH:
      return {
        longitude: 8.852233886718752,
        latitude: 46.09228143052649,
        radius: 301939,
      }
      break
    case LOCALE_CODES.NL:
      return {
        longitude: 4.76944,
        latitude: 52.123371,
        radius: 115951,
      }
      break
    case LOCALE_CODES.NL_BE:
      return {
        longitude: 4.755707,
        latitude: 51.517289,
        radius: 117529,
      }
      break
    case LOCALE_CODES.PT:
      return {
        longitude: -9.004669,
        latitude: 39.193948,
        radius: 146592,
      }
      break
      // every forgotten locale have en-us params
    default:
      return {
        longitude: 6.976318,
        latitude: 46.271037,
        radius: 992262,
      }
  }
}
