import { RequestReadYesboonySearch } from '@yescapa-dev/ysc-api-js/modern'
import type { AppCamperSearchQuery } from '@yescapa-dev/ysc-api-js/modern'

export const useSearchCampers = () => {
  const { $apiConsumer } = useNuxtApp()
  const { localeProperties } = useLocaleProperties()
  const searchCampers = async (query: AppCamperSearchQuery) => {
    /**
     * if no location provided, set default ones to get localized results
     * if marketing id is provided, skip default localization
    */
    if (!query.longitude && !query.latitude && !query.radius && !query.marketing_partnership) {
      const geoloc = getDefaultSearchGeoloc(localeProperties.yscCode)

      query = {
        ...query,
        ...geoloc,
      }
    }

    const request = new RequestReadYesboonySearch({ query })
    return await $apiConsumer.send(request)
  }

  return {
    searchCampers,
  }
}
